
/* eslint-disable */
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { invoiceSettings } from "./config";

export default defineComponent({
  // props: { iData: { type: Object, required: true } },
  data() {
    return {
      ...invoiceSettings,
    };
  },
  methods: {
    getInputElSize: function (str: string | number | any) {
      return str?.length > 0 ? str.length : 1;
    },
    getCurrencyFormat(val: number) {
      return (
        this.selectedCurrency.symbol +
        " " +
        val.toFixed(this.selectedCurrency.decimal_digits)
      );
    },
    getItemTotal(qty: number, unitCost: number) {
      if (qty < 1 || unitCost <= 0) return 0;
      return this.getCurrencyFormat(qty * unitCost);
    },
    addNewItem() {
      this.invoiceItems.push({
        sno: this.invoiceItems.length + 1,
        ...this.blankInvoiceItem,
      });
    },
    isHiddenField(field: string) {
      const $this = this as any;
      const $shouldHide = $this.invoiceHeaders[field].hidden;
      if ($shouldHide) return `print-hidden`;
    },
    adjustTextAreaHeight: function (event: Event | any) {
      var el = event.target;
      el.style.height = "1px";
      el.style.height = 0 + el.scrollHeight + "px";
    },
  },

  computed: {
    ...mapState(["invoiceCurrency"]),
    selectedCurrency() {
      return (this as any).invoiceCurrency?.selectedCurrency ?? {};
    },
    subTotal() {
      const $total = (this as any).invoiceItems.reduce(
        (accumulator: any, item: any) => {
          return accumulator + item.quantity * item.unitCost;
        },
        0
      );
      return $total;
    },
    discountAmount() {
      const $this = this as any;
      return $this.subTotal * ($this.discountRate / 100);
    },
    taxAmount() {
      const $this = this as any;
      return ($this.subTotal - $this.discountAmount) * ($this.invoiceTax / 100);
    },

    grandTotal() {
      const $this = this as any;
      return $this.subTotal - $this.discountAmount + $this.taxAmount;
    },
  },

  mounted() {
    this.addNewItem();
  },
});
