
import { defineComponent } from "vue";
import InvoiceContainer from "@/components/invoice/InvoiceContainer.vue";
import SimpleInvoice from "@/components/invoice-templates/Simple/SimpleInvoice.vue";

export default defineComponent({
  components: { InvoiceContainer, SimpleInvoice },
  data() {
    return {};
  },
});
