
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    printInvoice: function () {
      window.print();
    },
  },
});
