import { todayDate } from "@/utils/date";

const today = todayDate.format(`YYYY-MM-DD`);

export const invoiceOptions = {
  status: ["Pending", "Completed", "Draft", "Paid"],
  addressTextArea: {
    cols: 40,
    rows: 4,
  },
};

export const companyInfo = {
  name: `Your Company Name`,
  address: `#123, Your Address here
ABC Street
Your City, Your State - 123456
Your Country`,
};

export const customerInfo = {
  name: `Customer Name`,
  address: `#123, Customer Address here
XYZ Street
Cust City, Cust State - 123456
Cust Country`,
};

export const defaultSettings = {
  discountRate: 0,
  invoiceTax: 0,
};

export const invoiceHeaders = {
  sno: {
    title: `#`,
    class: `center`,
    editable: true,
    canHide: true,
  },
  name: {
    title: `Item`,
    editable: true,
    canHide: true,
  },
  description: {
    title: `Description`,
    editable: true,
    canHide: true,
  },
  unitCost: {
    title: `Unit Cost`,
    editable: true,
  },
  quantity: {
    title: `Qty`,
    editable: true,
  },
  total: {
    title: `Total`,
    editable: true,
  },
};

export const blankInvoiceItem = {
  name: `Item Name`,
  description: `Item Description`,
  unitCost: 0,
  quantity: 1,
};

// eslint-disable-next-line
export const invoiceItems: any[] = [];

export const invoiceData = {
  name: `Invoice #1`,
  date: today,
  status: (() => invoiceOptions.status[0])(),
};

export const invoiceSettings = {
  ...defaultSettings,
  companyInfo,
  customerInfo,
  invoiceData,
  invoiceOptions,
  invoiceHeaders,
  blankInvoiceItem,
  invoiceItems,
};
