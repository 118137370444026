import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8cb4300"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invoice-container flex flex-col" }
const _hoisted_2 = { class: "invoice-controls print-hidden" }
const _hoisted_3 = {
  class: "invoice-output",
  ref: "invOutSlotContainer"
}
const _hoisted_4 = { class: "hidden print-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_invoice_controls = _resolveComponent("invoice-controls")!
  const _component_branding_banner = _resolveComponent("branding-banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_invoice_controls)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", { ref: "invOutSlot" }, undefined, true)
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_branding_banner)
    ])
  ]))
}