import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "simple-invoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_invoice = _resolveComponent("simple-invoice")!
  const _component_invoice_container = _resolveComponent("invoice-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_invoice_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_simple_invoice)
      ]),
      _: 1
    })
  ]))
}